import { graphql, useStaticQuery } from "gatsby";
import { useEffect, useState } from "react";

export type ScreenSize = "mobile" | "tablet" | "restricted" | "full";

type ScreenSizeResponse = {
  screenSize: ScreenSize;
  screenSizeRaw: number;
};

function getWindowDimensions() {
  const { innerHeight, innerWidth } = window;

  return {
    width: innerWidth,
    height: innerHeight,
  };
}

const getScreenSize = (windowDimensions: { height: number; width: number }) => {
  let resScreenSize: ScreenSize = "full";

  if (windowDimensions.width <= 500) {
    resScreenSize = "mobile";
  } else if (windowDimensions.width <= 800) {
    resScreenSize = "tablet";
  } else if (windowDimensions.width <= 1250) {
    resScreenSize = "restricted";
  }

  return resScreenSize;
};

export const useScreenSize = (): ScreenSizeResponse => {
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );

  const [screenSize, setScreenSize] = useState(getScreenSize(windowDimensions));

  useEffect(() => {
    setScreenSize(getScreenSize(windowDimensions));
  }, [windowDimensions]);

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return {
    screenSize,
    screenSizeRaw: windowDimensions.width,
  };
};

export const useSiteMetadata = () => {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
          description
          siteUrl
        }
      }
    }
  `);

  return data.site.siteMetadata;
};
