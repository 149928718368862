import { ReactNode, createContext } from "react";
import React from "react";
import { ScreenSize, useScreenSize } from "../utils/hooks";

const ScreenSizeContext = createContext<{
  screenSize: ScreenSize;
  screenSizeRaw: number;
}>({
  screenSize: "full",
  screenSizeRaw: 1920,
});

export default ScreenSizeContext;

export const ScreenSizeContextProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const { screenSize, screenSizeRaw } = useScreenSize();
  return (
    <ScreenSizeContext.Provider value={{ screenSize, screenSizeRaw }}>
      {children}
    </ScreenSizeContext.Provider>
  );
};
